

































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { Loader } from "@googlemaps/js-api-loader";
import validator from "validator";
import useSelectItems from "@/use/selectItems";

interface StepError {
  step: number;
  error: string;
}

const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob([file]));
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default defineComponent({
  setup(_, { root, emit }) {
    const map = ref<HTMLElement | null>(null);
    const search = ref<HTMLInputElement | null>(null);

    const { languageItems } = useSelectItems({ root });

    const categoryItems = ["brak", "*", "**", "***", "****", "******"];

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      locationPhoto: "",
      map: null as google.maps.Map | null,
      marker: null as google.maps.Marker | null,
      autocomplete: null as google.maps.places.Autocomplete | null,
      oldImage: null as null | { id: string },
    });

    const model = reactive<{
      data: any;
      map: boolean;
      point: any;
    }>({
      data: {},
      map: false,
      point: { lat: 50.268652173195164, lng: 19.02734801786335 },
    });

    //  Start fetch single location data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`location/${root.$route.params.id}`)
        .then(({ data: { location } }) => {
          model.data.name = location.name || undefined;
          model.data.language = location.languageVersion || undefined;
          model.data.category = location.category || undefined;
          model.data.description = location.description || undefined;

          state.oldImage = location.image || undefined;

          model.data.city = location.city || undefined;
          model.data.address = location.street || undefined;
          model.data.postalCode = location.postalCode || undefined;
          if (location.latitude || location.longitude) {
            model.map = true;
            model.point.lat = location.latitude;
            model.point.lng = location.longitude;
          }

          model.data.phone = location.phoneNumber || undefined;
          model.data.email = location.email || undefined;
          model.data.website = location.website || undefined;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single location data

    // Start init map

    const initMap = () => {
      const loader = new Loader({
        apiKey: "AIzaSyDb2wq84EC-SfWreNnFZ7mbKSGQDPbnBc0",
        version: "weekly",
        libraries: ["places"],
      });

      const mapOptions = {
        center: { lat: 50.268652173195164, lng: 19.02734801786335 },
        zoom: 15,
      };

      loader.load().then(() => {
        state.map = new window.google.maps.Map(
          map.value as HTMLElement,
          mapOptions
        );

        state.marker = new window.google.maps.Marker({
          draggable: true,
          position: mapOptions.center,
          map: state.map,
        });

        state.marker.addListener("drag", () => {
          model.data.point = {
            lat: state.marker?.getPosition()?.lat() as number,
            lng: state.marker?.getPosition()?.lng() as number,
          };
        });

        state.autocomplete = new window.google.maps.places.Autocomplete(
          (search.value as any).$refs.input as HTMLInputElement
        );
        state.autocomplete.bindTo("bounds", state.map);
        state.autocomplete.addListener("place_changed", () => {
          const place = state.autocomplete?.getPlace();

          state.map?.setCenter(place?.geometry?.location as google.maps.LatLng);
          state.marker?.setPosition(
            place?.geometry?.location as google.maps.LatLng
          );

          model.data.point = {
            lat: state.marker?.getPosition()?.lat() as number,
            lng: state.marker?.getPosition()?.lng() as number,
          };
        });
      });
    };

    onMounted(initMap);

    watch(
      () => model.point,
      (newPoint) => {
        state.marker?.setPosition(
          new window.google.maps.LatLng(newPoint.lat, newPoint.lng)
        );
      },
      { deep: true }
    );

    // End init map

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          "Podaj poprawny kod pocztowy",
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          "Podaj poprawny numer telefonu",
      ],
    };

    watch(
      () => model.data.image,
      async (newPhoto) => {
        state.locationPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = state.oldImage?.id;

      const formData = new FormData();

      formData.append("image", model.data.image);

      if (model.data.image) {
        await axiosInstance
          .post("image/location", formData)
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        name: model.data.name,
        languageVersion: model.data.language || undefined,
        category: model.data.category || undefined,
        description: model.data.description || undefined,
        image: imageId || undefined,
        city: model.data.city,
        street: model.data.address || undefined,
        postalCode: model.data.postalCode || undefined,
        latitude: model.map ? model.point?.lat || undefined : undefined,
        longitude: model.map ? model.point?.lng || undefined : undefined,
        phoneNumber: model.data.phone || undefined,
        email: model.data.email || undefined,
        website: model.data.website || undefined,
      };

      state.loading = true;

      axiosInstance
        .put(`location/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data = {
            language: "Polski",
            category: "brak",
          };
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Obiekt już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/location/`
    );

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      languageItems,
      categoryItems,
      map,
      search,
      rules,
      filesUrl,
    };
  },
});
